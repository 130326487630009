// fetchFollowing.js
'use strict';
import parseLinkHeader from 'parse-link-header';

import fetch from '../resource/customFetch.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import getResourceUrl from '../resource/getResourceUrl.js';

import objectifyArrayById from '../resource/objectifyArrayById.js';
import getMeData from '../selector/getMeData.js';
import getListData from '../selector/getListData.js';
import savePosts from '../action/savePosts.js';
import { MessageMergeBehavior } from '../reducer/messages.js';
import {
  ADD_USERS,
  ADD_LIST_ITEMS,
  SET_LIST_ITEMS,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

/**
 * Fetch following
 * @kind action
 * @param {string} [{ query }] - search query string (optional).
 * @param {string} [{ via }] - form page (profile/following, profile/follower).
 * @param {number} [{ page }] - page number.
 * @param {number} [{ limit }] - page size.
 * @return {Promise} Action promise.
 */
const fetchFollowing =
  ({ query, page = 1, limit = 10 }) =>
  async (dispatch, getState) => {
    const token = getMeData(getState(), 'token');
    if (!token) {
      return;
    }

    const fetchOptions = {
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${token}`,
      },
    };
    const selectPath = ['following', page];
    const url = getResourceUrl({ endpoint: '/me/following' });
    url.searchParams.append('page', page);
    url.searchParams.append('limit', limit);

    if (query) {
      url.searchParams.append('q', query);
    }

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });

    try {
      let response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      const payload = (await response.json()) || [];
      const listSelectPath = ['following'];
      const links = parseLinkHeader(response.headers.get('Link'));
      const totalCount = parseInt(response.headers.get('x-total-count'), 10);
      const nextPage = links && links.next && parseInt(links.next.page, 10);
      const lastPage = links && links.last && parseInt(links.last.page, 10);
      const users = objectifyArrayById({ array: payload });
      const itemIds = Object.keys(users);
      const listActionType = page === 1 ? SET_LIST_ITEMS : ADD_LIST_ITEMS;

      dispatch({ type: ADD_USERS, payload: { users } });
      dispatch({
        type: listActionType,
        payload: {
          selectPath: listSelectPath,
          itemIds,
          nextPage,
          lastPage,
          totalCount,
        },
      });

      const messages = payload
        .filter(
          user =>
            user.metadata &&
            user.metadata.latestMessage &&
            user.metadata.latestMessage.id
        )
        .map(user => {
          return {
            ...user.metadata.latestMessage,
            senderId: user.id,
          };
        });

      const currentMessageIds =
        getListData(getState(), [...listSelectPath, 'story'], 'itemIds') || [];
      const messageIds = messages.map(message => message.id);
      dispatch(
        savePosts({
          posts: messages,
          behavior: MessageMergeBehavior.KEEP_OLD,
        })
      );
      dispatch({
        type: listActionType,
        payload: {
          selectPath: [...listSelectPath, 'story'],
          itemIds: messageIds,
          nextPage,
          lastPage,
          totalCount:
            SET_LIST_ITEMS === listActionType
              ? messageIds.length
              : messageIds.length + currentMessageIds.length,
        },
      });

      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchFollowing;
