// DisplayNameWithStatusIndicator.jsx
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextEllipsis from '../style/TextEllipsis.js';
import UserStatusIndicator from '../container/UserStatusIndicator.js';
import HighlightSubstring from '../component/HighlightSubstring.jsx';

export const DisplayNameWithStatusIndicator = ({
  id = '',
  query = '',
  username = '',
  displayName = '',
  isDisplayNameEnabled = true,
  isUserFetching = false,
  isUserFetched = false,
  isPrimaryTextOnly = false,
  fetchUser,
  marginTop = 12,
  fontSize = 12,
}) => {
  const timerRef = useRef();
  const usernameText = `@${username || ''}`;
  useEffect(() => {
    if (
      id &&
      (!username || (!displayName && !isUserFetching && !isUserFetched)) &&
      !timerRef.current
    ) {
      timerRef.current = setTimeout(() => fetchUser({ id }), 0);
    }
  }, [displayName, fetchUser, id, isUserFetched, isUserFetching, username]);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  // It should be either a display name or a username.
  // Show display name first and username as a fallback.
  const renderDisplayNameOrUsername = () => (
    <PrimaryText marginTop={marginTop} fontSize={fontSize}>
      <Username>
        {isDisplayNameEnabled && displayName ? (
          displayName
        ) : query ? (
          <HighlightSubstring substring={query}>
            {usernameText}
          </HighlightSubstring>
        ) : (
          usernameText
        )}
      </Username>
      <UserStatusIndicatorWrapper>
        <UserStatusIndicator
          userId={id}
          livestreamSize={12}
          shouldShowOnline
          shouldShowLiveStream
        />
      </UserStatusIndicatorWrapper>
    </PrimaryText>
  );

  // It should be a username or empty.
  const renderSecondaryText = () =>
    isDisplayNameEnabled ? (
      <SecondaryText>
        {displayName ? (
          query ? (
            <HighlightSubstring substring={query}>
              {usernameText}
            </HighlightSubstring>
          ) : (
            usernameText
          )
        ) : (
          ''
        )}
      </SecondaryText>
    ) : null;

  return (
    <>
      {renderDisplayNameOrUsername()}
      {!isPrimaryTextOnly && renderSecondaryText()}
    </>
  );
};

DisplayNameWithStatusIndicator.propTypes = {
  id: PropTypes.string,
  query: PropTypes.string,
  username: PropTypes.string,
  displayName: PropTypes.string,
  isDisplayNameEnabled: PropTypes.bool,
  isUserFetching: PropTypes.bool,
  isUserFetched: PropTypes.bool,
  isPrimaryTextOnly: PropTypes.bool,
  fetchUser: PropTypes.func.isRequired,
  marginTop: PropTypes.number,
  fontSize: PropTypes.number,
};

// Currently, we have 2 different font sizes,
// and they map to different area height respectfully:
// If the font size is 12, area height should be 18;
// if the font size is 16, area height should be 24;
const PrimaryText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  height: ${({ fontSize }) => `${fontSize === 12 ? 18 : 24}px`};
  margin-top: ${({ marginTop }) => `${marginTop}px`};
`;

const Username = styled.div`
  color: var(--font-color-primary-on-dark, #fff);
  ${TextEllipsis};
`;

const UserStatusIndicatorWrapper = styled.div`
  margin-left: 2px;
`;

const SecondaryText = styled.div`
  text-align: center;
  height: 18px;
  color: var(--font-color-secondary-on-dark, rgba(221, 221, 221, 0.8));
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  letter-spacing: 0.012px;
  ${TextEllipsis};
`;

export default DisplayNameWithStatusIndicator;
