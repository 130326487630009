// UserFeedItem.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import UserAvatar from '../container/UserAvatar.js';
import UserAvatarWithStatus from '../container/UserAvatarWithStatus.js';
import DisplayNameWithStatusIndicator from '../container/DisplayNameWithStatusIndicator.js';
import { ButtonId } from '../resource/mixpanel.js';
import FollowButton from '../container/FollowButton.js';
import { MediaAssetFormat } from '../resource/getMediaAsset.js';
import withViewportItemTracker from '../resource/withViewportItemTracker.js';

const srcSet = [
  MediaAssetFormat.AVIF,
  MediaAssetFormat.WEBP,
  MediaAssetFormat.JPG,
]
  .map(format => {
    return [
      { size: 128, resolution: '1x' },
      { size: 256, resolution: '2x' },
    ].map(size => ({ ...size, ext: format }));
  })
  .flatMap(a => a);

class UserFeedItem extends React.PureComponent {
  getTrackingPayload = () => {
    const { id, username } = this.props;
    return {
      'user.id': id,
      'user.username': username,
    };
  };
  renderDisplayNameWithStatusIndicator = () => {
    const { id, query, fontSize, marginTop, isPrimaryTextOnly } = this.props;
    return (
      <DisplayNameWithStatusIndicator
        id={id}
        query={query}
        fontSize={fontSize}
        marginTop={marginTop}
        isPrimaryTextOnly={isPrimaryTextOnly}
      />
    );
  };
  render() {
    const {
      withChatLink,
      withHalo,
      size,
      id,
      username,
      pathname,
      sendChatStart,
      shouldUseFollowButton,
      categoryIndex,
      categoryId,
      shouldTrackEvent,
    } = this.props;
    return (
      <StyledUserFeedItem size={size}>
        {withChatLink ? (
          <UserBlock
            title={username}
            onClick={() => sendChatStart({ userId: id })}
            data-element_id={ButtonId.All.ButtonChat}
            data-tracking_payload={this.getTrackingPayload()}
          >
            <UserAvatar id={id} size={size} srcSet={srcSet} />
            {this.renderDisplayNameWithStatusIndicator()}
          </UserBlock>
        ) : withHalo ? (
          <UserBlock title={username}>
            <UserAvatarWithStatus
              id={id}
              data-element_id={shouldTrackEvent && ButtonId.All.ButtonCpCard}
              data-tracking_payload={
                shouldTrackEvent && {
                  ...this.getTrackingPayload(),
                  'discover.index': categoryIndex,
                  'discover.category': categoryId,
                }
              }
              size={size}
              srcSet={srcSet}
              pathname={pathname}
              onClick={this.handleClickUser}
            />
            <Link
              to={`/user/${id}`}
              title={username}
              data-element_id={
                shouldTrackEvent && ButtonId.All.UserCircleCardName
              }
              data-tracking_payload={
                shouldTrackEvent && {
                  ...this.getTrackingPayload(),
                  'discover.index': categoryIndex,
                  'discover.category': categoryId,
                }
              }
            >
              {this.renderDisplayNameWithStatusIndicator()}
            </Link>
            {shouldUseFollowButton ? (
              <FollowButtonWrapper>
                <FollowButton
                  userId={id}
                  shouldKeepDisplay
                  color={'#191919'}
                  mixpanelTrackingPayload={this.getTrackingPayload()}
                />
              </FollowButtonWrapper>
            ) : null}
          </UserBlock>
        ) : (
          <Link to={`/user/${id}`} title={username}>
            <UserAvatar id={id} size={size} srcSet={srcSet} />
            {this.renderDisplayNameWithStatusIndicator()}
          </Link>
        )}
      </StyledUserFeedItem>
    );
  }
}

UserFeedItem.propTypes = {
  id: PropTypes.string,
  username: PropTypes.string,
  pathname: PropTypes.string,
  withHalo: PropTypes.bool,
  withChatLink: PropTypes.bool,
  size: PropTypes.number,
  fontSize: PropTypes.number,
  marginTop: PropTypes.number,
  sendChatStart: PropTypes.func,
  shouldTrackEvent: PropTypes.bool,
  categoryId: PropTypes.string,
  categoryIndex: PropTypes.number,
  query: PropTypes.string,
  shouldUseFollowButton: PropTypes.bool,
  isPrimaryTextOnly: PropTypes.bool,
};

UserFeedItem.defaultProps = {
  id: '',
  username: '',
  matchedString: '',
  pathname: '',
  withHalo: false,
  withChatLink: false,
  size: 88,
  fontSize: 16,
  marginTop: 16,
  sendChatStart: () => null,
  shouldTrackEvent: false,
  categoryId: '',
  categoryIndex: null,
  query: null,
  shouldUseFollowButton: false,
  isPrimaryTextOnly: false,
};

const StyledUserFeedItem = styled.section`
  width: ${({ size }) => `${size}px`};
  margin: 0 auto;
`;

const UserBlock = styled.div`
  display: block;
  text-align: center;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const FollowButtonWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
`;

export default withViewportItemTracker(UserFeedItem);
